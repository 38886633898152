<template>
  <div v-if="isVisible" style="z-index: 1004; position: fixed; height: 80px; bottom: 0; width: 100%" class="px-5">
    <v-fab color="contrast" rounded="0" class="d-flex align-end justify-end" icon="mdi-arrow-up"
           @click="scrollToTop"></v-fab>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import {useNuxtApp} from "#app";

const isVisible = ref(false);

const handleScroll = () => {
  const scrollPosition = window.scrollY + window.innerHeight;
  const pageHeight = document.documentElement.scrollHeight;
  isVisible.value = scrollPosition >= pageHeight - (pageHeight * 0.33);
};

const scrollToTop = () => {
  const {$scrollToTop} = useNuxtApp();
  $scrollToTop();
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>

</style>